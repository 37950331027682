<template>
    <div class="color-picker">
        <div class="field_wpr has_prefix" :class="classes">
            <input type="text" name="color" v-model="color" @focusout="updateColor" :placeholder="placeholder">
            <span class="prefix">
                <button type="button" class="show_picker" :id="uniqueId"></button>
            </span>
            <div class="prefix-right">
                <button v-if="showFavorite" type="button" class="add_color pointer" @click="handleStoreFavoriteColor">+ Add</button>
                <!-- <span class="tooltip">Add to favorite color</span> -->
            </div>
        </div>
        <div class="mt-3" v-if="showFavorite">
            <h3 class="sub_header pointer" @click="showFavoriteColor = !showFavoriteColor">Favorites<small><i :class="`fas ${showFavoriteColor ? 'fa-angle-up' : 'fa-angle-down'}`"></i></small> </h3>
            <ul class="swatches" v-show="showFavoriteColor">
                <li v-for="(color,  c) in favoriteColors" :key="c">
                    <span :style="`background:${color.color};`" @click.self="selectColor(color.color)">
                        <button type="button" class="del_color" @click="deleteFavoriteColor(color.id)"><i class="fas fa-times"></i></button>
                    </span>
                </li>
                <li v-if="defaultColor">
                    <span :style="`background:${defaultColor};`" @click.self="selectColor(defaultColor)">
                        <button type="button"></button>
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import Pickr from '@simonwep/pickr'
    import '@simonwep/pickr/dist/themes/monolith.min.css'

    import { uuid } from 'vue-uuid'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Color Picker',

        data () {
            return {
                uniqueId: 'picker-'+uuid.v4(),
                colorPicker: null,
                color: '#59c7f9',
                showFavoriteColor: false,
                timestamp: 0,
            };
        },

        props: {
            modelValue: {
                type: String,
                default: '#59c7f9',
            },
            placeholder: {
                type: String,
                default: '#59c7f9',
            },
            classes: {
                type: [String, Object],
                default: ''
            },
            showFavorite: {
                type: Boolean,
                default: true,
            },
            defaultColor: {
                type: String,
                default: '',
            }
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue: {
                handler (color) {
                    const vm = this;

                    setTimeout(function () {
                        vm.colorPicker.setColor(color);
                    }, 100);
                },
                deep: true
            }
        },

        computed: mapState({
            favoriteColors: state => state.commonModule.favoriteColors,
            favoriteColorsLoader: state => state.commonModule.favoriteColorsLoader,
        }),

        mounted () {
            const vm = this;

            if (vm.favoriteColors.length == 0 && !vm.favoriteColorsLoader) {
                vm.getFavoriteColors();
            }

            vm.colorPicker = Pickr.create({
                el: '#'+vm.uniqueId,
                theme: 'monolith',
                default: vm.modelValue,
                components: {
                    preview: true,
                    opacity: true,
                    hue: true,
                    interaction: {
                        input: true,
                    }
                }
            }).on('change', (color, source, instance) => {
                const timediff = (new Date).getTime() - vm.timestamp;

                if (Math.abs(timediff / 100) > 1) {
                    vm.handleColorChange(color.toHEXA().toString());

                    vm.timestamp = (new Date).getTime();
                }
            });

            vm.color = vm.modelValue;
        },

        methods: {
            ...mapActions({
                getFavoriteColors: 'commonModule/getFavoriteColors',
                storeFavoriteColor: 'commonModule/storeFavoriteColor',
                deleteFavoriteColor: 'commonModule/deleteFavoriteColor',
            }),

            handleColorChange (color) {
                const vm = this;

                vm.color = color;
                vm.colorPicker.setColor(color);
                vm.$emit('update:modelValue', color);
            },

            updateColor () {
                const vm = this;

                vm.colorPicker.setColor(vm.color);
                vm.$emit('update:modelValue', vm.color);
            },

            selectColor (color) {
                const vm = this;

                vm.color = color;
                vm.colorPicker.setColor(vm.color);
                vm.$emit('update:modelValue', vm.color);
            },

            handleStoreFavoriteColor () {
                const vm = this;

                vm.storeFavoriteColor({ color: vm.color });
            },
        },
    }
</script>

<style scoped>
    .field_wpr.has_prefix .prefix {
        width: 50px;
        height: 50px;
        border-right: 0px;
    }

    .field_wpr.has_prefix {
        padding-left: 50px;
    }

    :deep(.pickr .pcr-button) {
        width: 30px;
        height: 30px;
    }

    :deep(.pickr) {
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        /* border: 2px solid #fff; */
        box-shadow: 0 0px 1px rgb(0 0 0 / 25%);
    }

    .prefix-right {
        cursor: pointer;
        margin-left: auto;
        color: #32373b;
        font-weight: 500;
        padding: 0 10px;
        display: flex;
        align-items: center;
        background: rgba(0,0,0,.05);
        position: absolute;
        right: 5px;
        top: 5px;
        bottom: 5px;
        border-radius: 0 5px 5px 0;
    }

    .color-picker .swatches {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0 -7px;
    }

    .color-picker .swatches li {
        padding: 6px 7px;
    }

    .color-picker .swatches li span {
        width: 25px;
        height: 25px;
        border-radius: 5px;
        display: block;
        cursor: pointer;
        position: relative;
        box-shadow: 0 0px 1px rgb(0 0 0 / 25%);
    }

    .color-picker .swatches li span .del_color {
        position: absolute;
        right: -5px;
        top: -5px;
        width: 12px;
        height: 12px;
        font-size: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 0 5px rgb(0 0 0 / 30%);
        opacity: 0;
        transition: all .3s ease-in-out;
    }

    .color-picker .swatches li span:hover .del_color {
        opacity: 1;
    }

    .color-picker .sub_header {
        font-weight: 400;
        margin: 0 0 5px 0;
        font-size: 13px;
        line-height: 20px;
    }

    .prefix-right .tooltip {
        position: absolute;
        right: 0;
        top: 100%;
        padding: 4px 8px;
        border-radius: 3px;
        font-size: 11px;
        line-height: 13px;
        color: #fff;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        background: #32373b;
    }

    .prefix-right .tooltip:before {
        position: absolute;
        content: '';
        right: 20px;
        top: -3px;
        width: 6px;
        height: 6px;
        background: #32373b;
        transform: rotate(-45deg);
    }

    .prefix-right:hover .tooltip {
        opacity: 1;
    }
</style>
